<template>
  <div class="table-wrapper d-flex flex-column flex-grow-1" v-if="initialized">
    <TableHeaderPanel ref="tableHeaderPanel"
                      :loading="loading"
                      :addNewButton="addNewButton"
                      :filterButton="filterButton"
                      @onChangePeriod="doRequest"
                      @reset="resetPreferences"
                      @update-limit="updateLimit"
                      @search="searchPage"/>
    <div class="table-responsive flex-grow-1 d-flex flex-column px-3"
         style="height:100%; border-bottom:1px solid #c8ced3">
      <table class="table b-table table-hover table-sm border mh-100">
        <TableHeader :columnConfig="columnConfig"
                     :sortField="sortField"
                     :order="order"
                     @sort-update="updateSort"
                     @select-page="selectPage"
                     ref="header"/>
        <TableBody v-on="$listeners"
                   :list="list"
                   :start="page"
                   :columnConfig="columnConfig"
                   :selected="selected"
                   ref="body"
                   v-if="hasContent"/>
      </table>
    </div>
    <TableFooter class="bottom-controls"
                 :loading="loading"
                 :totalCount="totalCount"
                 :start="page"
                 :limit="limit"
                 :selected="selected"
                 @change-pagination="changePagination"/>

    <FilterModal ref="filterModal"
                 :filterConfig="filterConfig"
                 @okButton="saveModal"
                 @cancelButton="cancelModal"/>
  </div>
</template>

<script>
import TableHeaderPanel from './TableHeaderPanel.vue'
import TableHeader from './TableHeader.vue'
import TableBody from './TableBody.vue'
import TableFooter from './TableFooter.vue'
import FilterModal from './FilterModal'

export default {
  name: 'ListingComponent',
  components: {
    TableHeaderPanel,
    TableHeader,
    TableBody,
    TableFooter,
    FilterModal
  },
  props: {
    requestProvider: {
      type: Function,
      required: true
    },
    columnConfig: {
      type: Array,
      default: () => [],
      required: true
    },
    addNewButton: {
      type: Function
    },
    filterConfig: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      loading: false,
      initialized: false,
      list: [],
      totalCount: 0,
      selected: [],
      searchKey: '',
      clientId: null,
      marketplace: null,
      page: 1,
      limit: 20,
      sortField: '',
      order: 0,
      showAllPlaces : true
    }
  },
  computed: {
    hasContent () {
      return this.totalCount
    }
  },
  created () {
    this.onInitialize()
  },
  methods: {
    onInitialize () {
      this.$nextTick(() => {
        this.initialized = true
      })
      this.doRequest()
    },
    resetPreferences () {
      this.page = 1
      this.searchKey = ''
      this.clientId = null
      this.onInitialize()
    },
    doRequest () {
      this.loading = true

      this.requestProvider(this.getRequestParams())
        .then(({data}) => {
          if (data && data.data) {
            this.loading = false

            this.list = data.data.items
            this.totalCount = data.data.total || 0
          }
        }).catch(() => {
          this.loading = false
        })
    },
      getSortOrder() {
          return this.order === 0 ? 'asc' : 'desc'
      },
      getRequestParams() {
          const params = {
            start: (this.page - 1) * this.limit,
            limit: this.limit,
            searchKey: this.searchKey,
            sortField: this.sortField,
            clientId: this.clientId,
            sortOrder: this.getSortOrder(),
            showAllPlaces: this.showAllPlaces
        }
      return {params}
    },
    updateSort (sortField) {
      if (this.sortField === sortField) {
        this.order = this.order === 0 ? 1 : 0
      } else if (sortField === '') {
        this.order = 0
      }
      this.sortField = sortField
      this.doRequest()
    },
    searchPage () {
      this.page = 1

      this.doRequest()
    },
    updateLimit (limit) {
      this.limit = limit

      this.doRequest()
    },
    selectPage (state) {
      this.$refs.body.selectPage(state)
    },
    changePagination (page) {
      this.page = page

      this.doRequest()
    },
    saveModal () {
      this.$refs.filterModal.hideFilter()
      this.page = 1
      this.doRequest()
    },
    cancelModal () {
      this.$refs.filterModal.resetParams()
      this.$refs.filterModal.hideFilter()
    },
    filterButton () {
        return false
    }
  }
}
</script>

<style scoped>

</style>
