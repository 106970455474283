<template>
  <b-modal v-model="showModal"
           :title="modalTitle"
           @ok="$emit('okButton')"
           @cancel="$emit('cancelButton')"
           :no-close-on-backdrop="true"
           centered>
    <form>
      <b-form-group v-for="item in filterItems" :key="item.code">
        <label class="text-black-50">{{item.label}}</label>
        <template v-if="hasDatePicker(item.type)">
          <DatePicker v-model="filterData[item.code]"
                      format="yyyy-MM-dd"
                      placeholder="yyyy-MM-dd"
                      :typeable="true"
                      :clearButton="true"
                      :bootstrapStyling="true"
                      :calendarButton="true"
                      calendarButtonIcon="icon-calendar">
          </DatePicker>
        </template>
        <template v-else-if="hasDropDown(item.type)">
          <b-form-select v-model="filterData[item.code]" :options="options[item.code]"></b-form-select>
        </template>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import DatePicker from 'vuejs-datepicker'

export default {
  name: 'FilterModal',
  props: {
    filterConfig: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showModal: false,
      options: {},
      filterData: {}
    }
  },
  computed: {
    modalTitle () {
      if (!this.filterConfig) return
      return this.filterConfig.title ? this.filterConfig.title : ''
    },
    filterItems () {
      if (!this.filterConfig || !this.filterConfig.fields) {
        return []
      }
      return this.filterConfig.fields.map(field => {
        return {
          code: field.code,
          type: field.type,
          label: field.label,
          value: field.value
        }
      })
    }
  },
  methods: {
    hasDatePicker (type) {
      return type === 'DATE_PICKER'
    },
    hasDropDown (type) {
      return type === 'DROP_DOWN'
    },
    showFilter () {
      this.showModal = true
      if (Object.keys(this.options).length > 0) {
        return
      }
      for (let field of this.filterConfig.fields) {
        if (this.hasDropDown(field.type)) {
          this.filterConfig.filterApi(field.code, field.source)
            .then((response) => {
              if (response) {
                var options = [{value: null, text: 'Select'}]
                response.forEach(function (item) {
                  options.push({value: item.id, text: item.name})
                })
                this.$set(this.options, field.code, options)
                this.$set(this.filterData, field.code, null)
              }
            }).catch((error) => {
              this.$toastr.error(error)
            })
        } else if (this.hasDatePicker(field.type)) {
          this.$set(this.filterData, field.code, null)
        }
      }
    },
    hideFilter () {
      this.showModal = false
    },
    resetParams () {
      this.options = {}
      this.filterData = {}
    }
  },
  components: {
    DatePicker
  }
}
</script>

<style scoped>

</style>
