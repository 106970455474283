<template>

  <div class="">
    <div class="px-3 py-3 d-flex align-items-center">
      <div class="d-flex align-items-center">
        <render v-if="addNewButton" :render="addNewButton"/>
        <b-form-select :options="limitOptions"
                       value="20"
                       @change="$emit('update-limit', $event)"
                       class=" mr-3"/>
        <b-button :variant="'outline-secondary'"
                  class="mr-3"
                  @click="$emit('reset')">
          <i class="fas fa-sync-alt"></i>
        </b-button>
      </div>
      <div class="input-group w-auto mr-2">
        <input class="form-control "
               type="text"
               v-model="$parent.searchKey"
               @keyup.enter="$emit('search')"
               placeholder="Search">
        <span class="input-group-append">
          <b-button :variant="'outline-secondary'"
                    @click="$emit('search')">
            <i class="fas fa-search"></i>
          </b-button>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <render v-if="filterButton" :render="filterButton"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TableHeaderPanel',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    addNewButton: {
      type: Function
    },
    filterButton: {
      type: Function
    },
  },
  data () {
    return {
      modal: false,
      button: null,
      fromDate: null,
      toDate: null,
      limitOptions: [
        {value: 20, text: '20'},
        {value: 30, text: '30'},
        {value: 40, text: '40'},
        {value: 50, text: '50'}
      ]
    }
  },
  methods: {},
  components: {
  }
}
</script>
